<template>
  <div id="centerLeft1">
    <div class="bg-color-black" style=" position: relative;">
      <div class="d-flex pt-2 pl-2" style="position: absolute;top:0.2rem;left:0rem;padding-top:0">
        <span style="color: #5cd9e8">
          <icon name="chart-bar"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">{{ allData.title }} </span>
          <dv-decoration-3
            style="
              width: 1.25rem;
              height: 0.25rem;
              position: relative;
              top: -0.0375rem;
            "
          />
        </div>
      </div>
      <div class="d-flex jc-center" style="width:100%;margin-top:0.3rem">
        <CenterLeft1Chart :cdata="allData" />
      </div>
      <!-- 4个主要的数据 -->
      <div class="bottom-data">
        <div class="item-box" v-for="(item, index) in allData2.numberData" :key="index">
          <div class="d-flex">
            <!-- <span class="coin">￥</span> -->
            <dv-digital-flop
              :config="item.number"
              style="width: 2.5rem; height: 0.625rem"
            />
          </div>
          <p class="text" style="text-align: center">
            {{ item.text }}
            <span class="colorYellow">(个)</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CenterLeft1Chart from "@/components/echart/centerLeft1Chart";
export default {
  data() {
    return {
      
    };
  },
  props: {
    allData: {
      type: Object,
      default: () => ({}),
    },
    allData2: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    CenterLeft1Chart,
  },
  mounted() {
    // this.changeTiming();
  },
  methods: {
    changeTiming() {
      setInterval(() => {
        this.changeNumber();
      }, 3000);
    },
    changeNumber() {
      this.numberData.forEach((item, index) => {
        item.number.number[0] += ++index;
        item.number = { ...item.number };
      });
    },
  },
};
</script>

<style lang="scss">
#centerLeft1 {
  padding: 0.2rem;
  height: 100%;//5.125rem;
  min-width: 3.75rem;
  border-radius: 0.0625rem;
  .bg-color-black {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 100%;//4.8125rem;
    border-radius: 0.125rem;
  }
  .text {
    color: #c3cbde;
  }
  .chart-box {
    margin-top: 0.2rem;
    width: 2.125rem;
    height: 2.125rem;
    .active-ring-name {
      padding-top: 0.125rem;
    }
  }

  .bottom-data {
    .item-box {
      float: right;
      position: relative;
      width: 50%;
      color: #d3d6dd;
      // 金币
      .coin {
        position: absolute;
        left: 0.1rem;
        top: 0.2125rem;
        font-size: 0.25rem;
        color: #ffc107;
      }
      .colorYellow {
        color: yellowgreen;
      }
    }
  }
}
</style>