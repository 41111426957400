export const allData = {
    "order_detail": {
        "title": "订单概况",
        "seriesData": [
            {
                "name": "空泊位",
                "value": 11
            },
            {
                "name": "已占用",
                "value": 14
            },
            {
                "name": "计费中",
                "value": 998
            },
            {
                "name": "已结算",
                "value": 1023
            }
        ]
    },
    "order_detail_down": {
        "numberData": [
            {
                "number": {
                    "number": [
                        48
                    ],
                    "toFixed": 1,
                    "content": "{nt}"
                },
                "text": "未结"
            },
            {
                "number": {
                    "number": [
                        1210
                    ],
                    "toFixed": 1,
                    "content": "{nt}"
                },
                "text": "待确认"
            },
            {
                "number": {
                    "number": [
                        461
                    ],
                    "toFixed": 1,
                    "content": "{nt}"
                },
                "text": "已结"
            },
            {
                "number": {
                    "number": [
                        290
                    ],
                    "toFixed": 1,
                    "content": "{nt}"
                },
                "text": "拖欠"
            }
        ]
    },
    "user_list": {
        "titleItem": [
            {
                "number": {
                    "number": [
                        2154
                    ],
                    "toFixed": 0,
                    "content": "{nt}"
                },
                "title": "支付宝用户试营阶段"
            },
            {
                "number": {
                    "number": [
                        1498
                    ],
                    "toFixed": 0,
                    "content": "{nt}"
                },
                "title": "微信用户试营阶段"
            },
            {
                "number": {
                    "number": [
                        3300
                    ],
                    "toFixed": 0,
                    "content": "{nt}"
                },
                "title": "线下支付用户试营阶段"
            },
            {
                "number": {
                    "number": [
                        1230
                    ],
                    "toFixed": 0,
                    "content": "{nt}"
                },
                "title": "今日用户数"
            },
            {
                "number": {
                    "number": [
                        11009
                    ],
                    "toFixed": 0,
                    "content": "{nt}"
                },
                "title": "累计用户数"
            }
        ]
    },
    "park_one_range": {
        "title": "停车点收入排行",
        "ranking": {
            "carousel": "single",
            "unit": "元",
            "data": [
                {
                  "name": "惠民大道",
                  "value": 4000
                },
                {
                  "name": "恒丰",
                  "value": 2200
                },
                {
                  "name": "恵民小区",
                  "value": 2000
                },
                {
                  "name": "环城东路",
                  "value": 1500
                },
                {
                  "name": "公交公司",
                  "value": 900
                },
                {
                  "name": "政务中心侧",
                  "value": 900
                }
              ]
        }
    },
    "pay_num": {
        "rate": [
            {
                "title": "微信支付",
                "id": "centerRate1",
                "tips": 75,
                "colorData": {
                    textStyle: "#3fc0fb",
                    series: {
                        color: ["#00bcd44a", "transparent"],
                        dataColor: {
                            normal: "#03a9f4",
                            shadowColor: "#97e2f5",
                        },
                    },
                },
            },
            {
                "title": "支付宝支付",
                "id": "centerRate2",
                "tips": 19,
                "colorData": {
                    textStyle: "#67e0e3",
                    series: {
                        color: ["#faf3a378", "transparent"],
                        dataColor: {
                            normal: "#ff9800",
                            shadowColor: "#fcebad",
                        },
                    },
                },
            },
            {
                "title": "线下扫码",
                "id": "centerRate3",
                "tips": 5,
                "colorData": {
                    textStyle: "#67e0e3",
                    series: {
                        color: ["#10556b", "transparent"],
                        dataColor: {
                            normal: "#59c4e6",
                            shadowColor: "#a5e7f0",
                        },
                    },
                },
            },
            {
                "title": "现金支付",
                "id": "centerRate4",
                "tips": 1,
                "colorData": {
                    textStyle: "#67e0e3",
                    series: {
                        color: ["#144a27", "transparent"],
                        dataColor: {
                            normal: "#9fe5b8",
                            shadowColor: "#cdf2da",
                        },
                    },
                },
            }
        ]
    },
    "car_user_info": {
        "config": {
            "header": [
                "停车区位",
                "车位数",
                "剩余车位",
                "收入"
            ],
            "data": [
                [
                    "惠民大道停车点",
                    "170",
                    "50%",
                    "0"
                ],
                [
                    "恒丰停车点",
                    "104",
                    "15%",
                    "0"
                ],
                [
                    "恵民小区停车点",
                    "103",
                    "22%",
                    "0"
                ],
                [
                    "环城东路停车点",
                    "51",
                    "5%",
                    "0"
                ],
                [
                    "公交公司停车点",
                    "50",
                    "1%",
                    "0"
                ],
                [
                    "政务中心侧停车点",
                    "47",
                    "3%",
                    "0"
                ],
                [
                    "文笔停车点",
                    "36",
                    "3%",
                    "0"
                ],
                [
                    "农商行停车点",
                    "26",
                    "8%",
                    "0"
                ],
                [
                    "交通停车点",
                    "20",
                    "10%",
                    "0"
                ]
            ],
            rowNum: 6, //表格行数
            headerHeight: 35,
            headerBGC: "#0f1325", //表头
            oddRowBGC: "#0f1325", //奇数行
            evenRowBGC: "#171c33", //偶数行
            index: true,
            columnWidth: [50,150,80,100,100],
            align: ["center","center","center","center","center"],
        }
    },
    "device_use": {
        "title": "设备利用率",
        "cdata": {
            "legendData": [
                "已使用",
                "未使用"
            ],
            "category": [
                "一月",
                "二月",
                "三月",
                "四月",
                "五月",
                "六月",
                "七月",
                "八月",
                "九月",
                "十月",
                "十一月",
                "十二月"
            ],
            "lineData": [
                2000,
                2000,
                2000,
                2000,
                2000,
                2000,
                2000,
                2000,
                2000,
                0,
                0,
                0
            ],
            "barData": [
                500,
                500,
                500,
                500,
                500,
                500,
                500,
                500,
                500,
                0,
                0,
                0
            ]
        }
    },
    "park_space_pic": {
        "title": "车场收入趋势图",
        "cdata": {
            "yAxisName": "单位/元",
            "year": 2021,
            "weekCategory": [
                "9/10",
                "9/11",
                "9/12",
                "9/13",
                "9/14",
                "9/15",
                "9/16",
                "9/17",
                "9/18",
                "9/19",
                "9/29"
            ],
            "radarData": [
                [59, 6.067, 7.607, 0,   ],
                [50, 3.601, 5.101, 0,   ],
                [55, 4.036, 4.786, 0,   ],
                [53, 6.825, 8.495000000000001, 0,   ],
                [56, 4.504, 5.143999999999999, 0,   ],
                [52, 7.139, 8.309000000000001, 0,   ],
                [53, 7.311, 9.181000000000001, 0,   ],
                [55, 3.209, 4.719, 0,   ],
                [57, 4.138, 7.098, 0,   ],
                [51, 7.11, 7.140000000000001, 0,   ],
                [56, 3.166, 5.086, 0,   ],
            ],
            "radarDataAvg": [
              [4514, 6.647, 7.747, 0,   ],
              [10147, 4.597, 4.837000000000001, 0,   ],
              [9476, 5.684, 6.2540000000000004, 0,   ],
              [7609, 5.198, 6.558000000000001, 0,   ],
              [10520, 5.217, 5.467, 0,   ],
              [10487, 7.627, 8.937, 0,   ],
              [6739, 5.009, 6.399, 0,   ],
              [5168, 7.134, 7.824, 0,   ],
              [8707, 7.094, 7.864000000000001, 0,   ],
              [10937, 4.933, 6.503, 0,   ],
               [10015, 4.833, 5.603, 0,   ],
            ],
            "weekLineData": [
                10000,
                9100,
                10300,
                10005,
                8900,
                7609,
                9476,
                10937,
                6739,
                10147,
                4514
            ],
            "legendData": [
                "平均指标",
                "我的指标"
            ],
            "maxData": 10
        }
    }

}