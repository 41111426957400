<template>
  <div style="height:100%;padding:.2rem 0 0 0.2rem">
    <span style="color: #5cd9e8;">
      <icon name="align-left"></icon>
    </span>
    <span class="fs-xl text mx-2 mb-1">{{ cdata.title }} </span>
    <Chart :cdata="cdata.cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue'
export default {
  data () {
    return {
      
    };
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    Chart,
  },
  mounted () {
    // this.setData();
  },
  methods: {
    // 根据自己的业务情况修改
    setData () {
      for (let i = 0; i < this.cdata.barData.length -1; i++) {
        let rate = this.cdata.barData[i] / this.cdata.lineData[i];
        this.cdata.rateData.push(rate.toFixed(2));
      }
    },
  }
};
</script>

<style lang="scss" scoped>

</style>