<template>
  <div class="test">
    <!-- 年度开工率 -->
    <Echart
      :options="options"
      id="bottomLeftChart"
      height="4.6rem"
      width="100%"
    ></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        console.log('newData',newData)
        this.options = {
          title: {
            text: "",
          },
          tooltip: {
            trigger: "axis",
            formatter:function(data){
              return `已使用：${data[0].value}%`
            },
            backgroundColor: "rgba(255,255,255,0.1)",
            axisPointer: {
              type: "shadow",
              label: {
                show: true,
                backgroundColor: "#7B7DDC",
              },
            },
          },
          legend: {
            data: newData.legendData, //[ "已贯通","计划贯通","贯通率"],//"使用数", "利用率",
            textStyle: {
              color: "#B4B4B4",
            },
            top: "0%",
          },
          grid: {
            left: "3%",
            right: "4%",
            top: "5%",
            height:"80%",
            containLabel: true,
          },

          xAxis: {
            data: newData.category,
            axisLine: {
              lineStyle: {
                color: "black",
              },
            },
            axisTick: {
              show: false,
            },
            //解决文字过长，让文字倾斜
            axisLabel: {
              interval: 0,
              rotate: 20,
            },
          },
          yAxis: [
            {
              splitLine: { show: false },
              axisLine: {
                lineStyle: {
                  color: "#B4B4B4",
                },
              },

              axisLabel: {
                formatter: "{value}%",
              },
            },
            {
              splitLine: { show: false },
              axisLine: {
                lineStyle: {
                  color: "#B4B4B4",
                },
              },
              axisLabel: {
                formatter: "{value}",
              },
            },
          ],
          series: [
            //去掉折线数据
            {
              name: newData.legendData[0],
              type: "line",
              smooth: true,
              showAllSymbol: true,
              symbol: "emptyCircle",
              symbolSize: 8,
              yAxisIndex: 1,
              itemStyle: {
                normal: {
                  color: "#F02FC2"
                }
              },
              data: newData.barData
            },
            {
              name: newData.legendData[1],
              type: "bar",
              barWidth: 10,
              itemStyle: {
                normal: {
                  barBorderRadius: 5,
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "#956FD4" },
                    { offset: 1, color: "#3EACE5" },
                  ]),
                },
              },
              data: newData.lineData,
            },
            {
              name: newData.legendData[1],
              type: "bar",
              barGap: "-100%",
              barWidth: 10,
              itemStyle: {
                normal: {
                  barBorderRadius: 5,
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "rgba(156,107,211,0.8)" },
                    { offset: 0.2, color: "rgba(156,107,211,0.5)" },
                    { offset: 1, color: "rgba(156,107,211,0.2)" },
                  ]),
                },
              },
              z: -12,
              data: newData.barData,
            },
          ],
        };
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.test {
  width: 100%;
  height: 100%;
  // margin-top: 0.5rem;
  display: flex;
  align-items: center;
}
</style>