<template>
  <div>
    <Echart
      :options="options"
      id="mLineChart"
      height="5.5rem"
      width="100%"
    ></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        let series = [];
        newData.data.legendData.map((item, index) => {
          series.push({
            name: item,
            type: "line",
            stack: "Total",
            areaStyle: {},
            emphasis: {
              focus: "series",
            },
            data: newData.data.yAxisData[index],
          });
        });

        this.options = {
          title: {
            // text: newData.title,
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              label: {
                backgroundColor: "#6a7985",
              },
            },
          },
          legend: {
            data: [
              // "Email",
              // "Union Ads",
              // "Video Ads",
              // "Direct",
              // "Search Engine",
            ],
          },
          toolbox: {
            feature: {
              saveAsImage: {},
            },
          },
          grid: {
            left: "3%",
            right: "4%",
            top: "10%",
            height:"80%",
            containLabel: true,
          },
          xAxis: [
            {
              type: "category",
              boundaryGap: false,
              data: newData.data.xAxisData,
            },
          ],
          yAxis: [
            {
              type: "value",
            },
          ],
          series: series,
        };
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>