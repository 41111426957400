<template>
  <div>
    <Echart
      :options="options"
      id="cloumnChart"
      height="2.15rem"
      width="100%"
    ></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        this.options = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            height:"82%",
            containLabel: true,
          },
          xAxis: [
            {
              type: "category",
              data: newData.cdata.weekCategory,
              axisTick: {
                alignWithLabel: true,
              },
            },
          ],
          yAxis: [
            {
              type: "value",
            },
          ],
          series: [
            {
              name: "",
              type: "bar",
              barWidth: "60%",
              data: newData.cdata.weekLineData,
            },
          ],
        };
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>