<template>
  <div style="padding:.2rem 0 0 0.2rem">
    <span style="color: #5cd9e8">
      <icon name="align-left"></icon>
    </span>
    <span class="fs-xl text mx-2 mb-1">{{ cdata.title }} </span>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from "./chart.vue";
export default {
  data() {
    return {};
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    Chart,
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>