<template>
  <div>
    <Echart
      :options="options"
      id="lineChart"
      height="2.15rem"
      width="100%"
    ></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        console.log("newData", newData);
        this.options = {
          xAxis: {
            type: "category",
            data: newData.cdata.weekCategory,
          },
          yAxis: {
            type: "value",
          },
           grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            height:"85%",
            containLabel: true,
          },
          tooltip: {
            trigger: "axis",
            formatter: "{b} : {c}",
          },
          series: [
            {
              data: newData.cdata.weekLineData,
              type: "line",
              smooth: true,
            },
          ],
        };
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>