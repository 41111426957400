<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  data () {
    return {
      // cdata: {
      //   // xData: ["rose1", "rose2", "rose3", "rose4", "rose5", "rose6"],
      //   // seriesData: [
      //   //   { value: 10, name: "未结" },
      //   //   { value: 5, name: "待确认" },
      //   //   { value: 15, name: "已结" },
      //   //   { value: 25, name: "拖欠" },
      //   // ]
      // }
    }
  },
  props:{
    cdata:{
      type: Object,
      default: () => ({})
    }
  },
  components: {
    Chart,
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>