<template>
  <div id="index">
    <dv-full-screen-container class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="body-box" style="margin-top: 0rem">
          <!-- 第三行数据 -->
          <div class="content-box">
            <div style="flex: 1">
              <dv-border-box-12>
                <centerLeft1
                  :allData="allData.order_detail"
                  :allData2="order_detail_down"
                />
              </dv-border-box-12>
            </div>

            <div style="flex: 2">
              <lineChart :cdata="allData.park_space_pic" />
              <center :ranking="allData.device_use" />
            </div>

            <div style="flex: 1">
              <columnChart :cdata="allData.average_park_space_utilization" />
              <center :ranking="allData.park_lot_generate_count_revenue" />
            </div>
            <div style="flex: 1">
              <lineChart :cdata="allData.park_lot_ing_count" />

              <center :ranking="park_one_range" />
            </div>
            <div style="flex: 1">
              <shapeChart :cdata="allData.park_in_and_off_count" />
              <center :ranking="allData.park_lot_generate_revenue" />
            </div>
          </div>

          <!-- 第四行数据 -->
          <div class="bototm-box">
            <dv-border-box-13>
              <mLineChart :cdata="allData.peak_entry_and_exit" />
            </dv-border-box-13>
            <dv-border-box-12>
              <bottomLeftChart
                :cdata="allData.condition_of_supply_and_demand"
              />
            </dv-border-box-12>
          </div>
        </div>
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
let timer = {};
import { formatTime } from "../utils/index.js";
import centerLeft1 from "./centerLeft1";
import center from "./center";
import lineChart from "../components/echart/lineChart";
import mLineChart from "../components/echart/mLineChart";
import bottomLeftChart from "../components/echart/bottomLeftChart";
import columnChart from "../components/echart/columnChart";
import shapeChart from "../components/echart/shapeChart";

import { allData } from "../common/allData";
//data
export default {
  data() {
    return {
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      allData: {},
      order_detail_down: {},
      config: {},
      park_one_range: {},
      marker: {},
    };
  },
  components: {
    centerLeft1,
    center,
    lineChart,
    columnChart,
    mLineChart,
    bottomLeftChart,
    shapeChart,
  },
  beforeDestroy() {
    clearTimeout(timer);
  },
  mounted() {
    this.timeFn();
    this.cancelLoading();
    this.getData();

    setInterval(() => {
      this.changeNumber();
    }, 3000);
  },
  methods: {
    getData() {
      let that = this;
      let datas = {};
      this.$http("http://api.zf.syzytech.com/citylot/data/v2")
        .then((res) => {
          console.log("res", res);

          if (res.data.code !== 200) {
            this.allData = allData;
            this.config = allData.car_user_info.config;
            this.park_one_range = allData.park_one_range;
            this.order_detail_down = allData.order_detail_down;

            return;
          } else {
            // timer = setTimeout(() => {
            //   this.getData();
            // }, 3000);
          }
          datas = res.data.data;
          //订单概况
          if (
            JSON.stringify(this.order_detail_down) !==
            JSON.stringify(datas.order_detail_down)
          ) {
            console.log("请求order_detail_down");
            this.order_detail_down = datas.order_detail_down;
          }

          //停车点收入排行
          if (
            JSON.stringify(this.park_one_range) !==
            JSON.stringify(datas.park_one_range)
          ) {
            console.log("请求park_one_range");
            this.park_one_range = datas.park_one_range;
          }

          this.allData = datas;

          console.log("this.allData", this.allData);
        })
        .catch((err) => {
          console.log("err", err);
          this.allData = allData;
          this.config = allData.car_user_info.config;
          this.park_one_range = allData.park_one_range;
          this.order_detail_down = allData.order_detail_down;
        });
    },
    timeFn() {
      setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH: mm: ss");
        this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000);
    },
    changeNumber() {
      // this.order_detail_down.numberData.forEach((item, index) => {
      //   item.number.number[0] += ++index;
      //   item.number = { ...item.number };
      // });
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/index.scss";
.content-box {
  display: grid;
  grid-template-columns: 2fr 3fr 2fr 2fr 2fr !important;
  grid-template-rows: 100%;
}
</style>