<template>
  <div id="center">
    <div class="down" style="margin-top:.1rem" :style="height ? 'height:100%' : ''">
      
      <div class="ranking bg-color-black">
        <span style="color: #5cd9e8">
          <icon name="align-left"></icon>
        </span>
        <span class="fs-xl text mx-2 mb-1">{{ ranking.title }}  </span>
        <dv-scroll-ranking-board :config="ranking.ranking" style="height: 2.2rem" />
      </div>
      
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  props: {
    ranking: {
      type: Object,
      default: () => ({}),
    },
    height: {
      type: String,
      default: '',
    },
  },
  mounted(){
    
  },
  components: {
    // CenterChart,
    // centerChart1,
    // centerChart2
  },
};
</script>

<style lang="scss" scoped>
#center {
  height: 100%;
  display: flex;
  flex-direction: column;
  .up {
    width: 100%;
    height: 40%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .item {
      border-radius: 0.0625rem;
      padding-top: 0.2rem;
      margin-top: 0.1rem;
      width: 32%;
      // height: 0.875rem;
    }
  }
  .down {
    height: 48%;
    padding: 0.07rem 0.05rem;
    padding-bottom: 0;
    width: 100%;
    display: flex;
    // height: 3.1875rem;
    justify-content: space-between;
    .bg-color-black {
      border-radius: 0.0625rem;
    }
    .ranking {
      padding: 0.125rem;
      width: 100%;
    }
    .percent {
      width: 40%;
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 50%;
        // height: 1.5rem;
        span {
          margin-top: 0.0875rem;
          display: flex;
          justify-content: center;
        }
      }
      .water {
        width: 100%;
      }
    }
  }
}
</style>